export const INBOX_TEST_IDS = {
  inboxPage: 'inbox-page',
  inboxMenu: 'inbox-menu',
  inboxMenuToggle: 'inbox-menu-toggle',
  inboxMenuToggleClose: 'inbox-menu-toggle-close',
  inboxSearchConversationsFilterTab: 'inbox-search-conversations-filter-tab',
  inboxConversationsSearchbar: 'inbox-conversations-search-bar',
  inboxConversationsStatusFilterButton:
    'inbox-conversations-status-filter-button',
  inboxShopifyStoreDialog: 'inbox-shopify-store-dialog',
  inboxFilterConversationTrigger: 'inbox-filter-conversation-trigger',
  inboxConversationWindow: 'inbox-conversation-window',
  inboxCollaboratorsMenuTrigger: 'inbox-collaborators-menu-trigger',
  inboxFilterLabelsPanel: 'inbox-filter-labels-panel',
  inboxMyInboxMenuItem: 'my-inbox-menu-item',
  inboxCompanyInboxMenuItem: 'company-inbox-menu-item',
  inboxAddCollaboratorsMenu: 'inbox-add-collaborators-menu',
  inboxAddCollaboratorsButton: 'inbox-add-collaborators-button',
  inboxCollaboratorsSearchInput: 'inbox-collaborators-search-input',
  inboxAddCollaboratorsList: 'inbox-add-collaborators-list',
  inboxCollaboratorMenuExistingCollaboratorsList:
    'inbox-collaborator-menu-existing-collaborators-list',
  inboxExistingCollaboratorsList: 'inbox-existing-collaborators-list',
  inboxConversationInput: 'inbox-conversation-input',
  inboxSavedReplySuggestions: 'inbox-saved-reply-suggestions',
  inboxConversationUserProfile: 'inbox-conversation-user-profile',
  inboxSearchConversationList: 'search-conversations-list',
  inboxUnassignedTab: 'inbox-unassigned-tab',
  inboxAssignedTab: 'inbox-assigned-tab',
  inboxAiSmartSummaryButton: 'inbox-ai-smart-summary-button',
  inboxAiSmartReplyButton: 'inbox-ai-smart-reply-button',
  inboxAiEnrichmentDiscardChangesButton:
    'inbox-ai-enrichment-discard-changes-button',
  inboxAiEnrichmentConfirmChangesButton:
    'inbox-ai-enrichment-confirm-changes-button',
  inboxAiEnrichmentWritingAssistantButton:
    'inbox-ai-enrichment-writing-assistant-button',
  inboxAiEnrichmentContentContainer: 'inbox-ai-enrichment-content-container',
  inboxAiCustomPromptInput: 'inbox-ai-custom-prompt-input',
  inboxAiCustomPromptProceedButton: 'inbox-ai-custom-prompt-proceed-button',
  inboxAiCustomPromptCancelButton: 'inbox-ai-custom-prompt-cancel-button',
  inboxConversationInputTextbox: 'inbox-conversation-input-textbox',
  inboxConversationInputAttachmentButton:
    'inbox-conversation-input-attachment-button',
  inboxConversationInputEmojiButton: 'inbox-conversation-input-emoji-button',
  inboxConversationInputAudioButton: 'inbox-conversation-input-audio-button',
  inboxConversationInputSavedReplyButton:
    'inbox-conversation-input-saved-reply-button',
  inboxConversationInputProductCatalogButton:
    'inbox-conversation-input-product-catalog-button',
  inboxConversationInputPaymentLinkButton:
    'inbox-conversation-input-payment-link-button',
  inboxConversationInputChannelSelect:
    'inbox-conversation-input-channel-select',
  inboxConversationInputSendMessageButton:
    'inbox-conversation-input-send-message-button',
  inboxConversationInputScheduleMessageButton:
    'inbox-conversation-input-schedule-message-button',
  inboxConversationInputWhatsappInteractionMenu:
    'inbox-conversation-input-whatsapp-interaction-menu',
} as const;
